
































































import { Component, Vue, Prop } from "vue-property-decorator";
import SubscribeUpdates from "@/components/SubscribeUpdates.vue";
import { DateFormatPreview } from "@/services/DateFormat/DateFormatPreview";
import { STATUS_SUCCESS } from "@/constants/testStatuses";

@Component({
  components: { SubscribeUpdates },
  filters: {
    formatDate(date: string): string {
      return DateFormatPreview.getFullDate(date);
    },
    getYear(date: string): number {
      return new Date(date).getFullYear();
    },
  },
})
export default class ExamsStatusBar extends Vue {
  @Prop({ type: Boolean, required: true }) status!: string;
  @Prop({ type: String, default: "" }) finishedAt!: string;
  @Prop({ type: String, default: "" }) minRightAnswers!: string;
  @Prop({ type: String, default: "" }) pendingAfterFail!: string;
  @Prop({ type: String, default: "" }) userEmail!: string;
  @Prop({ type: Function }) userUpdateEmailFn!: () => Promise<void>;
  @Prop({ type: String, default: "" }) resultDescClass!: string;
  get isSuccessStatus(): boolean {
    return this.status === STATUS_SUCCESS;
  }

  get getTimeNextTesting(): string {
    const MILISECONDS_IN_MINUTE = 60000; // 1m * 50s * 1000ms
    const examExitTime = new Date(this.finishedAt).getTime();
    const pendingTime =
      parseInt(this.pendingAfterFail || "0") * MILISECONDS_IN_MINUTE;

    return new Date(examExitTime + pendingTime).toISOString();
  }
}
