import { ObjectOf } from "@/types/ObjectOf";
import * as STATUSES from "@/constants/testStatuses";

export const EXAM_STATUS_CONFIG: ObjectOf<{ slug: string; class: string }> = {
  [STATUSES.STATUS_FAIL]: {
    slug: STATUSES.STATUS_FAIL_SLUG,
    class: "color-red",
  },
  [STATUSES.STATUS_SUCCESS]: {
    slug: STATUSES.STATUS_SUCCESS_SLUG,
    class: "color-green",
  },
  [STATUSES.STATUS_PENDING]: {
    slug: STATUSES.STATUS_PENDING_SLUG,
    class: "color-blue",
  },
  [STATUSES.STATUS_IN_PROGRESS]: {
    slug: STATUSES.STATUS_IN_PROGRESS_SLUG,
    class: "",
  },
} as const;
