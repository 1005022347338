




































































import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { REGEX_EMAIL } from "@/constants/regex";
import MaterialIcon from "@/components/UI/MaterialIcon.vue";
import InputField from "@/components/form/InputField.vue";

@Component({
  components: {
    MaterialIcon,
    InputField,
  },
})
export default class SubscribeUpdates extends Vue {
  public localEmail = "";
  public showEditRow = true;
  public loading = false;

  @Prop({ type: String, required: true }) email!: string;
  @Prop({ type: Function, required: true }) updateSubscribe!: (
    email: string | null
  ) => Promise<void>;

  get isValidEmail(): boolean {
    const EMAIL_MAX_LENGTH = 64;
    return (
      this.localEmail.length <= EMAIL_MAX_LENGTH &&
      REGEX_EMAIL.test(this.localEmail)
    );
  }

  get isActive(): boolean {
    return this.showEditRow && Boolean(this.email);
  }

  @Watch("email")
  private setLocalEmail(email?: string): void {
    this.localEmail = email || "";
    this.showEditRow = Boolean(this.email);
  }

  mounted(): void {
    this.setLocalEmail(this.email);
  }

  async subscribe(email: string | null): Promise<void> {
    if (this.email === email) {
      return;
    }

    this.loading = true;

    await this.updateSubscribe(email);

    this.loading = false;
  }
}
